<template>
  <div class="hubspot-form">
    <h2>Contact</h2>
    <div id="hubspotForm" v-once></div>
  </div>
</template>

<script>
export default {
  name: "HubspotForm",
  mounted: function() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "22310586",
          formId: "3337ef84-f3ea-4aa6-92d6-76c2384f74ed",
          target: "#hubspotForm"
        });
      }
    });
  }
};
</script>

<style lang="scss">
.hubspot-form {
  background-color: #fff;
  padding: 0 20px;
}
</style>
