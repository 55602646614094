<template>
  <div id="license-music">
    <div class="background">
      <img src="@/assets/background-top-02.svg" />
    </div>
    <Navbar class="white-text light-menu-icon" />

    <section id="power-your-business">
      <h1 class="page-title">Power your business<br />with Rumblefish</h1>
      <p>
        Use Rumblefish - our signature licensing and administration service -
        for a one-stop solution to manage administration, licensing, data or
        royalty management needs. We have the systems and team in place to
        simplify and manage licensing accurately so you can stay focused on your
        business.
      </p>
      <img
        src="@/assets/power-your-business.png"
        alt="Power your business with Rumblefish"
      />
      <a @click="showConsultationForm" class="button primary shadow"
        >LEARN MORE</a
      >

      <div class="clients">
        <p>
          Our clients include premier streaming services, music distributors,
          tablature and lyrics companies, labels, fitness and other apps,
          background music companies, karaoke, AR/VR, online video services and
          more.
        </p>
        <h2>A select list of our clients</h2>
        <img
          class="mobile-only"
          src="@/assets/hfa-clients-mobile.png"
          alt="Some of our clients"
        />
        <img
          class="desktop-only"
          src="@/assets/hfa-clients.png"
          alt="Some of our clients"
        />
      </div>
    </section>

    <section id="benefits-of-rumblefish">
      <h2 class="page-title">Benefits of Rumblefish</h2>
      <div class="benefits">
        <div class="benefit">
          <img src="@/assets/right-team.png" alt="Right Team" />
          <h3>Right Team</h3>
          <p>
            You didn't sign up to become an expert in the complicated,
            difficult, and confusing world of music rights administration. We
            did, that's our mission, and our team of copyright and technology
            experts are here to help.
          </p>
        </div>
        <div class="benefit">
          <img
            src="@/assets/right-relationships.png"
            alt="Right Relationships"
          />
          <h3>Right Relationships</h3>
          <p>
            Our relationships and partners are best in class in every industry
            category. Leverage our deep ties to the music and technology
            community to facilitate licensing and royalty administration. Let us
            open doors for you.
          </p>
        </div>
        <div class="benefit">
          <img src="@/assets/right-services.png" alt="Right Services" />
          <h3>Right Services</h3>
          <p>
            Tap into our one-stop shop for all your data, licensing and royalty
            needs to streamline your operations. With decades of technical
            expertise and tireless execution, Rumblefish is here to support your
            music distribution innovation.
          </p>
        </div>
      </div>
    </section>

    <div class="background bg-02">
      <img src="@/assets/background-01.svg" alt="background image" />
    </div>

    <section id="our-services">
      <h2 class="page-title">Our Services</h2>
      <div class="for-digital-brands">
        <h3>For Digital Brands and Media Companies</h3>
        <p>
          Our Rumblefish team works with many major brands to take care of their
          music clearances and royalty distributions. Learn how Rumblefish can
          work with you to address your music licensing needs.
        </p>
        <a @click="showConsultationForm" class="button primary shadow"
          >LEARN MORE</a
        >
        <img src="@/assets/our-services.svg" alt="For Digital Brands" />
      </div>
      <div class="for-music-creators">
        <h3>For Music Creators</h3>
        <p>
          Whether you are an independent artist, school or religious
          organization making and distributing music,<br />we make it easy for
          you to obtain licenses for your recordings
        </p>
        <a
          href="http://www.songfile.com/"
          target="_blank"
          class="button primary"
          >Sign Up For Songfile</a
        >
        <img
          src="@/assets/services-for-music-creators.svg"
          alt="For Music Creators"
        />
      </div>
      <div class="for-record-labels">
        <h3>For Record Labels</h3>
        <p>
          If you are an indie label, or a publishing company, we have a range of
          services to streamline your mechanical licensing and administration
          for both physical and digital formats.
        </p>

        <div class="cards">
          <div class="card" v-for="(card, index) in cards" :key="index">
            <img :src="card.icon" :alt="card.title" />
            <h4>{{ card.title }}</h4>
            <p>{{ card.content }}</p>
            <a
              v-if="card.href"
              :href="card.href"
              target="_blank"
              class="button primary shadow"
              >{{ card.button }}</a
            >
            <a
              v-if="card.click"
              @click="card.click"
              class="button primary shadow"
              >{{ card.button }}</a
            >
            <a v-if="card.to" :href="card.to" class="button primary shadow">{{
              card.button
            }}</a>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import HubspotForm from "@/components/HubspotForm.vue";

export default {
  name: "LicenseMusic",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - License Music",
      meta: [
        {
          name: "description",
          content:
            "Use Rumblefish - our signature licensing and administration service - for a one-stop solution to manage administration, licensing, data or royalty management needs."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      cards: [
        {
          title: "Songfile",
          content:
            "Labels that distribute up to 2,500 physical units, digital downloads or ringtones, or up to 10,000 units for interactive streams",
          button: "SIGN UP FOR SONGFILE",
          href: "http://www.songfile.com/",
          icon: require("../assets/wave-bars.png")
        },
        {
          title: "HFA",
          content:
            "Labels that distribute over 2,500 physical units, digital downloads or ringtones, or over 10,000 interactive streams",
          button: "APPLY FOR LICENSING ACCOUNT",
          to: "#/setup-hfa-portal",
          icon: require("../assets/speaker.png")
        },
        {
          title: "Rumblefish",
          content:
            "Our comprehensive licensing and royalty administration service offering for all types of formats",
          button: "LEARN MORE",
          click: this.showConsultationForm,
          icon: require("../assets/headphones.png")
        }
      ]
    };
  },
  methods: {
    showConsultationForm: function() {
      this.$modal.show(
        HubspotForm,
        {
          router: this.$router
        },
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  }
};
</script>

<style lang="scss">
#license-music {
  > .background {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    overflow: hidden;
    width: 100vw;

    img {
      width: 500vw;
      transform: translate(-25%, 0%);
    }

    @media (min-width: $desktop) {
      overflow: visible;
      width: auto;

      img {
        width: 69.986vw;
        transform: none;
      }
    }

    &.bg-02 {
      top: initial;
      right: initial;
      left: 0;
      width: 100vw;
      overflow: hidden;
      transform: translateY(-5%);

      img {
        width: 300vw;
        transform: translateX(-35%);
      }

      @media (min-width: $desktop) {
        top: initial;
        right: initial;
        left: 0;
        width: 100vw;
        overflow: hidden;
        height: 119vw;
        transform: translateY(-22%);

        img {
          width: 135vw;
          transform: translateX(-20%);
        }
      }
      // @media (min-height)
    }
  }

  section {
    margin-bottom: 20vh;

    h3 {
      font-family: "Antic Slab", serif;
      font-weight: 400;
      font-size: 1.75em;
    }
    .button {
      font-size: 1.375em;
      margin-top: 7.687vw;
      padding: 0.8em 0;
      width: 260px;
      display: block;
    }

    @media (min-width: $desktop) {
      margin-bottom: 20vh;
    }
  }

  #power-your-business {
    margin-top: 5vh;

    h1,
    p {
      color: #fff;
    }
    h1 {
      font-size: 1.75em;
      letter-spacing: 0.2px;
      margin-top: 0;
      margin-bottom: 3.001vw;
    }
    p {
      font-size: 1em;
      line-height: 1.89;
      width: auto;
    }
    > img {
      position: relative;
      display: block;
      width: 80%;
      margin: -60px auto 10px;
      z-index: -1;
    }
    a {
      font-size: 1em;
      display: block;
      margin: 0 auto;
    }
    .clients {
      margin: 50px 0 0;

      h2 {
        margin: 0;
        text-align: center;
        font-size: 1.3em;
      }
      img {
        width: 100%;
        margin: 0;
        z-index: 1;
      }
    }

    @media (min-width: $desktop) {
      margin-top: 10vh;

      h1,
      p {
        color: #000;
      }
      h1 {
        font-size: 2.25em;
      }
      p {
        font-size: 1.1875em;
        width: 487px;
      }
      > img {
        position: absolute;
        top: 0;
        right: 6.882vw;
        width: 43.266vw;
        margin: 0;
      }
      a {
        font-size: 1.375em;
        margin: 2vw 0 0;
      }

      .clients {
        h2 {
          text-align: left;
        }
        img {
          width: 60%;
        }
      }
    }
    @media (min-height: 550px) {
      margin-top: 15vh;
    }
  }
  #benefits-of-rumblefish {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-bottom: 50vh;

    h2 {
      width: 100%;
      text-align: center;
      letter-spacing: 0.2px;
      font-size: 1.25em;
      margin-bottom: 50px;
    }
    .benefits {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin-bottom: 170px;

      .benefit {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        margin-bottom: 50px;

        h3 {
          text-align: center;
          margin: 10px 0 0;
          font-size: 1.1em;
        }
        p {
          line-height: 2;
          letter-spacing: 0.2px;
          text-align: center;
        }
        img {
          display: block;
          margin: 0 auto 0;
          width: 15%;
        }
      }
    }
    .consultation {
      width: 100%;

      .button {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: $desktop) {
      margin-bottom: 18.4vw;
      margin-left: -50px;

      h2 {
        font-size: 1.75em;
        margin-bottom: 50px;
      }
      .benefits {
        flex-direction: row;
        width: 75vw;

        .benefit {
          width: 236px;

          img {
            width: 60%;
          }
          h3 {
            margin: 1em 0;
            font-size: 1.75em;
          }
          p {
            text-align: left;
          }
        }
      }
    }
  }
  #our-services {
    line-height: 1.68;

    > div {
      position: relative;
      margin-bottom: 28vw;
    }
    h2 {
      color: #fff;
      font-size: 1.25em;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1.1em;
    }
    p {
      font-size: 1em;
    }
    img {
      position: absolute;
    }
    a.button {
      font-size: 1em;
      margin: 5em auto;
    }

    @media (min-width: $desktop) {
      margin-top: -8.5vw;

      h2 {
        font-size: 2em;
        margin-bottom: 50px;
      }
      h3 {
        font-size: 1.75em;
      }
      p {
        font-size: 1.1875em;
      }
      a.button {
        font-size: 1.375em;
        margin: 7.687vw 0 0;
      }
    }

    .for-digital-brands {
      color: #fff;

      img {
        position: relative;
        display: block;
        margin: 10px auto;
        width: 90vw;
      }

      @media (min-width: $desktop) {
        p {
          width: 65%;
        }
        img {
          width: 50vw;
          position: absolute;
          right: 50px;
          top: -40px;
        }
      }
      @media (min-width: 1700px) {
        margin-bottom: 32vw;
      }
    }
    .for-music-creators {
      margin-bottom: 20vh;

      p {
        width: 60vw;
      }
      img {
        width: 45vw;
        top: 20px;
        right: -8px;

        @media (min-width: 380px) and (max-width: $mobile) {
          top: 10px;
          max-height: 256px;
        }
        a.button {
          margin: 24vw auto;
        }
      }

      @media (min-width: $desktop) {
        margin-bottom: 14vw;

        p {
          width: 35vw;
        }
        img {
          width: 25.916vw;
          top: 40px;
          right: 14.6413vw;
        }
      }
      @media (min-width: 1450px) {
        margin-bottom: 18vw;
      }
      @media (min-width: 1550px) {
        margin-bottom: 19vw;
      }
      @media (min-width: 1600px) {
        margin-bottom: 21vw;
      }
      @media (min-width: 1700px) {
        margin-bottom: 26vw;
      }
    }
    .for-record-labels {
      p {
        margin-bottom: 70px;
      }
      .cards {
        display: flex;
        flex-direction: column;

        .card {
          position: relative;
          width: 100%;
          box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          margin-bottom: 20px;
          background-color: #fff;

          h4,
          p {
            margin-left: 20px;
            margin-right: 20px;
          }
          h4 {
            font-size: 1.2em;
            font-family: "Fjalla One", sans-serif;
            font-weight: 400;
            margin-bottom: 8px;
          }
          p {
            margin-top: 0;
            margin-bottom: 20px;
          }

          .button {
            margin: 40px auto;
          }

          img {
            width: 40px;
            position: absolute;
            top: 10px;
            right: 15px;
          }
        }
      }

      @media (min-width: $desktop) {
        margin-bottom: 0;

        .cards {
          flex-direction: row;
          justify-content: space-around;
          margin-left: -50px;

          .card {
            width: 30%;
            box-shadow: 0 22px 22px 0 rgba(0, 0, 0, 0.1);

            h4,
            p {
              margin-left: 60px;
              margin-right: 10px;
            }
            h4 {
              margin-top: 85px;
              margin-bottom: 8px;
              font-size: 1.375em;
            }
            img {
              width: 70px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile) {
    #navbar {
      .logo {
        img {
          filter: invert(100%);
        }
      }
    }
  }
}
</style>
